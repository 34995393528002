
.component-container {
    background-color: #f5f5f5;
    padding: 20px;
}

.logo {
    display: flex;
    justify-content: center;
}

/* Update the CSS for the logo image */
.logo img {
    max-width: 25%; /* Set a maximum width relative to its parent */
    height: auto; /* Maintain the aspect ratio */
}

.introductions {
    color: black;
    border-bottom: 1px solid #ffbf00;
    margin-bottom: 20px;
}

/* Add a horizontal line between the internal-link and external-link */
.internal-link {
    border-bottom: 1px solid;
    margin-bottom: 20px;
}

.internal-link p {
    margin-bottom: 20px;
}

.external-link {
    border-bottom: 1px solid;
    margin-bottom: 20px;
}
.external-link p {
    margin-bottom: 20px;
}

.navigation a {
    display: block; /* Make links block-level elements to create space between them */
    margin-bottom: 20px;
}